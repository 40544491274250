import { Component, OnInit } from '@angular/core';
import {MaintenanceService} from "../service/maintenance.service";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(private maintenanceService: MaintenanceService) { }

  ngOnInit(): void {
    this.maintenanceService.checkMaintenance().subscribe(data => {
      if (!data.in_maintenance) {
        document.location.href = '/';
      }
    });
  }
}

<div class="remote-log-container">
  <div class="title-page">
    Remote Log (total: {{logs.length}})
    <div class="title-toolbar">
      <span class="link-action" label="Refresh" (click)="refreshLogs()">Refresh</span>
      <p-checkbox class="link-action" [binary]="true" (click)="toggleRefresh()" [(ngModel)]="autoRefresh" label="Auto refresh"></p-checkbox>
      <span title="Send a test log for check backend function" class="link-action" label="Send debug message" (click)="testSendLog()">Send debug message</span>
    </div>
  </div>
  <div class="remote-log-content">
    <div class="remote-log-item">
      <div class="remote-log>">
        <p-table
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 40]"
          [value]="logs">
          <ng-template pTemplate="header">
            <tr>
              <th>Action</th>
              <th>Id</th>
              <th>AppName</th>
              <th>Log level</th>
              <th>Time</th>
              <th>Event</th>
              <th>ErrorDump</th>
              <th>Last message</th>
              <th>Session time</th>
              <th>FPS</th>
              <th>Bitrate</th>
              <th>Jitter</th>
              <th>Log</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-log>
            <tr>
              <td class="first-column">
                <p-button (click)="deleteLog(log.id)" icon="pi pi-trash" size="small"></p-button>
              </td>
              <td>
                <span>{{ log.id }}</span>
              </td>
              <td>{{ log.application_name }}</td>
              <td>{{ log?.log_level }}</td>
              <td>{{ log.log_entry['timestamp'] }}</td>
              <td>{{ extractEventString(log.log_entry['event']) }}</td>
              <td class="error-dump">{{ log.log_entry['errorDump'] }}</td>
              <td>{{ extractLastMessage(log.log_entry['lastReceivedMessage']) }}</td>
              <td>{{ log.log_entry['debugData'] && log.log_entry['debugData']?.sessionStats?.runTime}}</td>
              <td>{{ log.log_entry['debugData'] && log.log_entry['debugData']?.fps}}</td>
              <td>{{ log.log_entry['debugData'] && log.log_entry['debugData']?.bitrate}}</td>
              <td>{{ log.log_entry['debugData'] && log.log_entry['debugData']?.jitter}}</td>
              <td class="last-column">
                <p-overlayPanel #op [style]="{'width': '850px', 'height': '50%', 'text-wrap': 'wrap', 'overflow-wrap': 'break-word', 'overflow': 'scroll'}" [showCloseIcon]="true">
                  <pre [style]="{'text-wrap': 'wrap', 'overflow-wrap': 'break-word'}">{{ log.log_entry|json }}</pre>
                </p-overlayPanel>
                <p-button (click)="op.toggle($event)" label="JSON"></p-button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private maintenancePrefix = `/gw/api/v1/maintenance/`;
  apiUrl = "";

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`;
  }

  checkMaintenance(): Observable<{ in_maintenance: boolean }> {
    return this.http.get<{ in_maintenance: boolean }>(this.apiUrl + this.maintenancePrefix + environment.client.split('-')[1]);
  }
}

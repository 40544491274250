import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatisticsAssetList, StatisticsExhibitionList, StatisticsRoomList } from './Statistics.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  apiUrl = '';
  constructor(
    private httpClient: HttpClient,
  ) {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`;
  }

  getExhibitionStatistics(): Observable<StatisticsExhibitionList> {
    return this.httpClient.get<StatisticsExhibitionList>(this.apiUrl + '/api/v1/metrics/exhibitions');
  }

  getAssetStatistics(): Observable<StatisticsAssetList> {
    return this.httpClient.get<StatisticsAssetList>(this.apiUrl + '/api/v1/metrics/assets');
  }

  getRoomStatistics(): Observable<StatisticsRoomList> {
    return this.httpClient.get<StatisticsRoomList>(this.apiUrl + '/api/v1/metrics/rooms');
  }
}

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PixelStreamingWrapperComponent } from './pixel-streaming-wrapper/pixel-streaming-wrapper.component';
import { SignupComponent } from './signup/signup.component';
import { authGuard } from './auth.guard';
import {ControlPanelComponent} from "./control-panel/control-panel.component";
import {MfaComponent} from "./mfa/mfa.component";
import {PixelStreammingContainerComponent} from "./pixel-streamming-container/pixel-streamming-container.component";
import {VerifyemailComponent} from "./verifyemail/verifyemail.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {UpdatePasswordComponent} from "./update-password/update-password.component";
import {HomeComponent} from "./home/home.component";
import {AssetUploadComponent} from "./asset-upload/asset-upload.component";
import {MetricsComponent} from "./metrics/metrics.component";
import {AdminControlPanelComponent} from "./admin-control-panel/admin-control-panel.component";
import { EditUserComponent } from './edit-user/edit-user.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { canDeactivatePixelStreammingContainerComponentGuard } from './can-deactivate-guard';
import { DeleteAccountSuccessLandingPageComponent } from './delete-account-success-landing-page/delete-account-success-landing-page.component';
import {SystemStatusComponent} from "./system-status/system-status.component";
import { pixelstreamingGuard } from './pixelstreaming.guard';
import { StatisticsExportComponent } from './statistics-export/statistics-export.component';
import {AssetManagerComponent} from "./asset-manager/asset-manager.component";
import {DebugLogManagerComponent} from "./debug-log-manager/debug-log-manager.component";
import {MaintenanceComponent} from "./maintenance/maintenance.component";
import {maintenanceGuard} from "./maintenance.guard";

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'user' }},
  {path: 'pixelstreaming', component: PixelStreammingContainerComponent, canActivate: [maintenanceGuard, pixelstreamingGuard], canDeactivate: [canDeactivatePixelStreammingContainerComponentGuard] },
  {path: 'pixelstreaming/:exhibitionId', component: PixelStreammingContainerComponent, canActivate: [maintenanceGuard, pixelstreamingGuard], canDeactivate: [canDeactivatePixelStreammingContainerComponentGuard] },
  {path: 'controlpanel', component: ControlPanelComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'user' }},
  {path: 'mfa', component: MfaComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'user' }},
  {path: 'verify-email/:token', component: VerifyemailComponent, data: { hideNavBar: true }},
  {path: 'reset-password', component: PasswordResetComponent, data: { hideNavBar: true }},
  {path: 'update-password/:token', component: UpdatePasswordComponent, data: { hideNavBar: true }},
  {path: 'login', component: LoginComponent, canActivate: [maintenanceGuard], data: { hideNavBar: true }},
  {path: 'upload', component: AssetUploadComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'curator' }},
  {path: 'asset-manager', component: AssetManagerComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'curator' }},
  {path: 'logs', component: MetricsComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'statistics', component: StatisticsComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'export', component: StatisticsExportComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'admin-control-panel', component: AdminControlPanelComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'admin-control-panel/add-user', component: SignupComponent, canActivate: [maintenanceGuard, authGuard], data: { addUser: true, role: 'admin' }},
  {path: 'admin-control-panel/:userId', component: EditUserComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'system-status', component: SystemStatusComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'debug', component: DebugLogManagerComponent, canActivate: [maintenanceGuard, authGuard], data: { role: 'admin' }},
  {path: 'user-deletion-confirm', component: DeleteAccountSuccessLandingPageComponent, data: { hideNavBar: true }},
  { path: 'maintenance', component: MaintenanceComponent },
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignupRequest } from '../model/signupRequest';
import JwtUtil from "../utils/JwtUtil";
import {AuthenticatorResponse} from "../model/AuthenticatorResponse";
import {JwtToken} from "../model/JwtToken";
import {ExtendedJwtPayload} from "../model/ExtendedJwtPayload";
import {Observable, map} from "rxjs";
import {UserEntityResponse} from "../model/UserEntityResponse";
import {RoleMappingResponse} from "../model/RoleMappingResponse";
import {Page} from "../model/Page";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class  UserService {
  apiUrl = "";
  currentUser: ExtendedJwtPayload | null = null;
  constructor(private http: HttpClient, private jwtUtil: JwtUtil) {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}${environment.domain}`
  }

  setCurrentUser() {
    this.currentUser = this.jwtUtil.getDecodedToken();
  }

  getCurrentUser() {
    return this.currentUser;
  }

  isGuest() {
    return this.currentUser?.realm_access?.roles.includes('guest');
  }

  signup(username: string, email: string, firstName: string, lastName: string, password: string) {
    let body: SignupRequest = {
      username: username,
      email: email,
      firstName: firstName,
      lastName: lastName,
      enabled: true,
      credentials: [{
        value: password,
        temporary: false
      }]
    }
    return this.http.post(this.apiUrl + '/api/v1/user/signup', body);
  }

  getAuthSecret() {
    return this.http.get<AuthenticatorResponse>(this.apiUrl + '/api/v1/user/mfa/' + this.jwtUtil.getDecodedToken()?.sub);
  }
  saveAuth(secret: String) {
    let body = {
      userId: this.jwtUtil.getDecodedToken()?.sub,
      secret: secret
    }
    return this.http.post(this.apiUrl + '/api/v1/user/mfa', body);
  }
  deleteAuth() {
    return this.http.delete(this.apiUrl + '/api/v1/user/mfa');
  }

  verifyEmail(token: string) {
    return this.http.get(this.apiUrl + '/api/v1/user/verify-email/' + token);
  }

  sendPasswordResetEmail(email: string) {
    return this.http.get(this.apiUrl + '/api/v1/user/send-password-reset-email/' + email);
  }

  updatePassword(token: string, password: string) {
    return this.http.put(this.apiUrl + '/api/v1/user/reset-password', {
      token: token,
      password: password
    });
  }

  updateUser(userId: string,  email?: string, firstName?: string, lastName?: string, password?: string) {
    let body: SignupRequest = {
      username: email,
      email: email,
      firstName: firstName,
      lastName: lastName,
      enabled: true,
      credentials: password ? [{
          value: password,
          temporary: false
      }] : undefined
    }

    return this.http.put(this.apiUrl + '/api/v1/user/' + userId, body);
  }

  updateUserData(userId: string, userBody: any) {
    return this.http.put(this.apiUrl + '/api/v1/user/' + userId, userBody);
  }

  getAllUsers():Observable<UserEntityResponse[]> {
    return this.http.get<UserEntityResponse[]>(this.apiUrl + '/api/v1/user');
  }

  getUser(userId: string):Observable<UserEntityResponse> {
    return this.http.get<UserEntityResponse>(this.apiUrl + '/api/v1/user/find/' + userId);
  }

  getUserRoleMappings(userId: string): Observable<RoleMappingResponse[]> {
      return this.http.get<RoleMappingResponse[]>(this.apiUrl + '/api/v1/user/' + userId + '/role');
  }

  getUserHighestRole(userId: string): Observable<string> {
    return this.getUserRoleMappings(userId).pipe(
      map(roleMappings => {
        const roleScore: { [key: string]: number } = {'admin': 4, 'curator': 3, 'user': 2, 'guest': 1};
        let highestRoleScore = 1;
        let highestRole = 'guest';

        roleMappings.forEach(roleMapping => {
          if (roleMapping.name in roleScore) {
            const currentRoleScore = roleScore[roleMapping.name];
            if (currentRoleScore > highestRoleScore) {
              highestRoleScore = currentRoleScore;
              highestRole = roleMapping.name;
            }
          }
        });

        return highestRole;
      }),
    );
  }

  deleteRoleMapping(userId: String, roleMapping: RoleMappingResponse) {
    const options = {
      body: roleMapping,
    };
      return this.http.delete(this.apiUrl + '/api/v1/user/' + userId + '/role', options);
  }

  getRealmRoles(): Observable<RoleMappingResponse[]> {
    return this.http.get<RoleMappingResponse[]>(this.apiUrl + '/api/v1/user/roles');
  }

  addRoleToUser(userId: string, role: RoleMappingResponse) {
      return this.http.post(this.apiUrl + '/api/v1/user/' + userId + '/role', role);
  }

  searchUsers(id: string | undefined, email: string | undefined,
              firstName: string | undefined, lastName: string | undefined, enabled: boolean | undefined,
              page: number, size: number, sortBy: string, sortDir: string):Observable<Page<UserEntityResponse>> {
    let params = new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sortBy', sortBy)
        .set('sortDir', sortDir);

    if (id) params = params.set('id', id);
    if (email) params = params.set('email', email);
    if (firstName) params = params.set('firstName', firstName);
    if (lastName) params = params.set('lastName', lastName);
    if (enabled) params = params.set('enabled', enabled);

    return this.http.get<Page<UserEntityResponse>>(this.apiUrl + '/api/v1/user/search', {params: params});
  }

  deleteUser(id: string) {
    return this.http.delete(this.apiUrl + '/api/v1/user/' + id);
  }
}

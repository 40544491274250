<app-navbar *ngIf="showNavBar()"></app-navbar>
<router-outlet></router-outlet>
<p-toast position="bottom-center"></p-toast>
<app-spinner></app-spinner>
<p-confirmDialog key="disconnectDialog"></p-confirmDialog>
<div *ngIf="displayBrowserModal" class="browser-warning">
  <div id="content-warning">
    <p>Il tuo browser non è pienamente supportato. Per migliorare la tua esperienza aggiornalo ad una versione più recente. ( Versione attuale: {{browserName}} ver. {{browserVersion}})</p>
    <a class="close-btn" (click)="hideWarning()">
      <i class="pi pi-times"></i>
    </a>
  </div>
</div>

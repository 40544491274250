import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewEncapsulation, OnInit, Input, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import {Config, Flags, PixelStreaming, SettingFlag} from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.3';
import {PixelStreamingService} from '../service/pixel-streaming.service';
import {LoginService} from "../service/login.service";
import {
  Application,
  PixelStreamingApplicationStyle, SettingUIFlag,
  UIElementCreationMode
} from "@epicgames-ps/lib-pixelstreamingfrontend-ui-ue5.3";
import {MatchmakingService} from "../service/matchmaking.service";
import {BehaviorSubject, config, first, Subject, takeUntil} from "rxjs";
import {AuthMessage} from "../model/AuthMessage";
import JwtUtil from "../utils/JwtUtil";
import {MuseumService} from "../service/museum.service";
import {Router} from "@angular/router";
import {LangDataModel} from "../model/LangDataModel";
import {TranslateService} from "@ngx-translate/core";
import { OverlayPanel } from 'primeng/overlaypanel';
import { environment } from 'src/environments/environment';
import isMobile from 'is-mobile';
import { Base64Service } from '../base64.service';
import { ConfirmationService } from 'primeng/api';
import {StreamingDebugModel} from "../model/StreamingDebug";
import {UserService} from "../service/user.service";
import {ExtendedJwtPayload} from "../model/ExtendedJwtPayload";
import {RemoteLogService} from "../service/remote-log.service";

@Component({
  selector: 'app-pixel-streaming-wrapper',
  templateUrl: './pixel-streaming-wrapper.component.html',
  styleUrls: ['./pixel-streaming-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PixelStreamingWrapperComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild("videoParentElement", {static: true}) private videoParentElement!: ElementRef<HTMLElement>;
  PixelStreamingApplicationStyles = new PixelStreamingApplicationStyle();
  @Input() exhibitionId: number | null = null;
  isLogged: boolean = false;
  jwtToken: string = '';
  showPixelStreaming = true;
  retryTime = environment.matchmakingTimeout;
  application: Application | undefined = undefined;
  showUploadModal = false;
  displayPasteModal = false;
  textAreaValue = '';
  @ViewChild('op',{static:true}) op!: OverlayPanel;
  private ngUnsubscribe = new Subject<void>();
  currentUser: ExtendedJwtPayload | null = null;
  userRoles: string[] = [];
  debugEnabled = environment.streamingDebug;
  debugLevel = environment.streamingDebugLevel;
  debugData: BehaviorSubject<StreamingDebugModel> = new BehaviorSubject<StreamingDebugModel>(
    {
      bitrate: 0,
      jitter: 0,
      fps: 0,
      packetLost: 0,
      freeze: 0,
      totalFreezeDuration: 0,
      frameHeight: 0,
      frameWidth: 0,
    }
  );
  lastReceivedMessage: any = null;
  currentHost: string = "";
  private reconnectionFailed = 0;
  maxFailedReconnections = 3;
  constructor(private pixelStreamingService: PixelStreamingService,
              private loggingService: LoginService,
              private jwtUtil: JwtUtil,
              private museumService: MuseumService,
              private translate: TranslateService,
              private base64Service: Base64Service,
              private router: Router,
              private confirmDialogService: ConfirmationService,
              private changeDetectorRef: ChangeDetectorRef,
              private userService: UserService,
              private remoteLogService: RemoteLogService) {
    console.log("PixelStreamingWrapperComponent constructor");
    this.loggingService.token$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(token => {
      if (token) {
        this.jwtToken = token;
      }
    });
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser;
    this.userRoles = this.currentUser?.realm_access?.roles || [];
    console.log("application " + this.application);
    if (this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("admin")) {
        console.log("admin user");
    } else if (this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("curator")) {
        console.log("curator user");
    } else if (this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("user")) {
        console.log("user user");
    } else if (this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("guest")) {
        console.log("guest user");
    } else {
        console.log("could not retrieve a valid role");
    }
  }

  ngAfterViewInit() {
    this.initPixelStreaming();
  }

  ngOnDestroy() {
    if (this.application) {
      console.log("PixelStreamingWrapperComponent ngOnDestroy application");
      try {
        this.application.stream.webSocketController.sendUnsubscribe();
      } catch (error) {
        console.error('Error during sendUnsubscribe:', error);
      }
      try {
        this.application.stream.webSocketController.close();
      } catch (error) {
        console.error('Error during close:', error);
      }
      try {
        this.application.stream.disconnect();
        console.log("PixelStreamingWrapperComponent ngOnDestroy application disconnect");
      } catch (error) {
        console.error('Error during disconnect:', error);
      }

      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }
  initPixelStreaming() {
    this.pixelStreamingService.wsUrl$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(wsUrl => {
      console.log("+++++++++++++++++++++++++++++++++ " + wsUrl + " +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
      console.log(typeof wsUrl);
      this.currentHost = wsUrl;
      if (wsUrl && !wsUrl.includes("undefined")) {
        this.pixelStreamingService.changeSettings({
          initialSettings: {
            AutoPlayVideo: false, // to prevent sending duplicate WebSocket messages, the 'play' button is programmatically clicked within pixel-streaming-container.
            AutoConnect: true,
            TimeoutIfIdle: true,
            HoveringMouse: true,
            ss: `${wsUrl}`,
            StartVideoMuted: false,
            WaitForStreamer: true,
            GamepadInput: false,
            XRControllerInput: false,
            AFKTimeout: this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes('admin') ||
            this.jwtUtil.getDecodedToken()?.realm_access?.roles.includes("curator") ? 1800 : 375,
            ControlsQuality: true,
          },
        });

        this.showPixelStreaming = true;
        this.PixelStreamingApplicationStyles.applyStyleSheet();
        setTimeout(() => {
          this.pixelStreamingService.showTutorial.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => this.updateInputSettings(!res));
          this.pixelStreamingService.getSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(cfg => {
            console.log("+ + + getting config in subscription + + +")
            console.log(cfg);
            if (cfg && !this.application) {
              const stream = new PixelStreaming(
                new Config(cfg)
              );
                this.application = new Application({
                stream,
                onColorModeChanged: (isLightMode) => this.PixelStreamingApplicationStyles.setColorMode(isLightMode),
                settingsPanelConfig: {
                  isEnabled: false,
                  visibilityButtonConfig: {creationMode: UIElementCreationMode.Disable}
                },
                fullScreenControlsConfig:{
                  creationMode: /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) ? UIElementCreationMode.Disable : UIElementCreationMode.CreateDefaultElement,
                },
                statsPanelConfig: {
                  isEnabled: false,
                  visibilityButtonConfig: {creationMode: UIElementCreationMode.Disable}
                }
              });
              this.videoParentElement.nativeElement.appendChild(this.application.rootElement);
            }
          })
          if(this.application) {
            this.application.stream.addEventListener("webRtcDisconnected", (event) => {
              console.warn("Disconnected from Unreal Engine Pixel Streaming", event);
              this.pixelStreamingService.isDisconnected = true;
              const errorDump = this.application?.stream.webSocketController.webSocket.url;
              if (this.reconnectionFailed <= this.maxFailedReconnections && event && event?.data?.eventString) {
                try {
                  this.remoteLogService.sendRemoteLog({
                    currentUser: this.currentUser,
                    event: JSON.stringify(event),
                    debugData: this.debugData.value,
                    errorDump: errorDump,
                    lastReceivedMessage: this.lastReceivedMessage,
                    timestamp: new Date().toUTCString(),
                  }, 'ERROR').pipe(first()).subscribe();
                  this.reconnectionFailed++;
                  console.log("reconnectionFailed: " + this.reconnectionFailed);
                  this.initPixelStreaming();
                } catch (error) {
                  console.error('Error during sendRemoteLog:', error);
                }
              } else if (!event.data.allowClickToReconnect) {
                try {
                  this.remoteLogService.sendRemoteLog({
                    currentUser: this.currentUser,
                    event: JSON.stringify(event),
                    debugData: this.debugData.value,
                    errorDump: errorDump,
                    lastReceivedMessage: this.lastReceivedMessage,
                    timestamp: new Date().toUTCString(),
                  }, 'ERROR').pipe(first()).subscribe();

                } catch (error) {
                  console.error('Error during sendRemoteLog:', error);
                }
              }
              if (this.reconnectionFailed > this.maxFailedReconnections) {
                const messageHeader = event.data.allowClickToReconnect ? this.translate.instant('info.webRtcDisconnectedHeader') : this.translate.instant('info.unknownDisconnectedHeader');
                const messageDescription = event.data.allowClickToReconnect ? this.translate.instant('info.webRtcDisconnectedDescription') : this.translate.instant('info.unknownDisconnectedDescription') + `<br><br><pre><code>(Error data (${new Date().toUTCString()}): ${errorDump})</code></pre>`;
                this.router.navigate(['/']).then(succeeded => {
                  if (succeeded) {
                    this.confirmDialogService.confirm({
                      key: 'disconnectDialog',
                      header: messageHeader,
                      message: messageDescription,
                      rejectVisible: false,
                      acceptLabel: 'Ok',
                      accept: () => {
                        // reload single page application
                        window.location.reload();
                      }
                    });
                  }
                });
              };
            });
            this.application.stream.addEventListener("playStream", () => {
              console.log("onConnected");
              this.sendPixelStreamingMsg();
            });
            this.application.stream.addEventListener("statsReceived", (stats) => {
              this.debugData.next({
                bitrate: stats.data.aggregatedStats?.lastVideoStats.bitrate,
                jitter: stats.data.aggregatedStats?.lastVideoStats.jitter,
                fps: stats.data.aggregatedStats?.lastVideoStats.framesPerSecond,
                packetLost: stats.data.aggregatedStats?.lastVideoStats.packetsLost,
                freeze: stats.data.aggregatedStats?.lastVideoStats.freezeCount,
                totalFreezeDuration: stats.data.aggregatedStats?.lastVideoStats.totalFreezesDuration,
                frameHeight: stats.data.aggregatedStats?.lastVideoStats.frameHeight,
                frameWidth: stats.data.aggregatedStats?.lastVideoStats.frameWidth,
                sessionStats: stats.data.aggregatedStats?.sessionStats,
                streamStats: stats.data.aggregatedStats?.streamStats
              });
            });
            this.application.stream.addEventListener("afkTimedOut", () => {
              console.log("afkTimedOut");
              this.pixelStreamingService.setWsUrl('');
              this.pixelStreamingService.isDisconnected = true;
              this.router.navigate(['/']).then(succeeded => {
                if (succeeded) {
                  this.confirmDialogService.confirm({
                    key: 'disconnectDialog',
                    header: this.translate.instant('info.timeoutHeader'),
                    message: this.translate.instant('info.timeoutDescription'),
                    rejectVisible: false,
                    acceptLabel: 'Ok',
                  });
                }
              });
            });
            this.application.stream.addResponseEventListener("observa-msgs", (data: any) => {
              console.log(data);
              this.receivedMessagesHandler(data);
            });
            this.application.stream.addEventListener("webRtcConnected", (event) => {
              console.log("webRtcConnected", event);
              this.reconnectionFailed = 0;
            });
            this.application.stream.webSocketController.webSocket.addEventListener("close", (event) => {
              console.log("WebSocket closed", event);
            });
          }

        }, 1000);

      }
    }, error => console.error('Error pixelstreaming service:', error));
  }


  sendPixelStreamingMsg() {
    let msgData: AuthMessage = {
      token: this.jwtToken,
      refreshToken: '',
      clientId: environment.client,
    }

    let langData: LangDataModel = {
      type: "language-change",
      data: this.translate.currentLang
    }
    const isMobileData = {
      type: 'isMobile',
      data:  isMobile({ tablet: true, featureDetect: true }) + '',
    };
    this.loggingService.refreshToken$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(refreshToken => {
      console.log("refreshToken: " + refreshToken)
      if (refreshToken) {
        msgData.refreshToken = refreshToken;
      }
          setTimeout(() => {
            this.application?.stream.emitUIInteraction({type: 'auth', payload: JSON.stringify(msgData)});
            this.application?.stream.emitUIInteraction({type: 'application', payload: JSON.stringify(langData)});
            this.application?.stream.emitUIInteraction({type: 'application', payload: JSON.stringify(isMobileData)});
            console.log(`Set language on Unreal: ${msgData}`);
            if (this.exhibitionId) {
              console.log("sending open-show message to pixel streaming with exhibitionId: " + this.exhibitionId);
              this.application?.stream.emitUIInteraction({type: 'application', payload: JSON.stringify({
                  type: 'open-show',
                  data: this.exhibitionId + ""
                })
              })
            }
          }, 1000);
    });
  }

  receivedMessagesHandler(data: any) {
    let parsedData = JSON.parse(data);
    let parsedPayload = JSON.parse(parsedData.payload);
    this.lastReceivedMessage = data;
    console.log(data);
    console.log(parsedData);
    switch (parsedPayload.type) {
      case "logout":
        this.router.navigate(['/login']).then(succeeded => {
          if (succeeded) {
            this.loggingService.logout();
          }
        });
        break;
      case "link":
        console.log("opening link: " + parsedPayload.data);
        window.open(parsedPayload.data, '_blank');
        break;
      case "upload":
        this.showUploadModal = true;
        this.changeDetectorRef.detectChanges()
      break;
      case "generate-link":
        let url = window.location.href + "/" + parsedPayload.data;
        navigator.clipboard.writeText(url);
        this.application?.stream.emitUIInteraction({type: "application", payload: JSON.stringify({
            type: "notification",
            data: "Url copiato nella clipboard"
          })})
        break;
      case "wasdmode":
        if (JSON.parse(parsedPayload.data)) {
          console.log("wasd mode " + parsedPayload.data)
          this.application?.stream.config.setFlagEnabled("HoveringMouse", false);
          document.getElementById("streamingVideo")?.click();
        } else {
          this.application?.stream.config.setFlagEnabled("HoveringMouse", true);
        }
        break;
      case "token":
        this.loggingService.setJwt(parsedPayload.data.token)
        this.loggingService.setRefreshToken(parsedPayload.data.refreshToken)
        break;
      case "open-paste-box":
        try {
          this.textAreaValue = this.base64Service.base64ToString(parsedPayload.data);
        } catch(err) {
          this.textAreaValue = '';
        }
        this.op.show({});
        break;
      case "close-paste-box":
        this.textAreaValue = '';
        this.op.hide();
        break;
      case "open-tutorial":
        if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
          document.getElementById('fullscreen-btn')?.click();
        }
        this.pixelStreamingService.showTutorial.next(true);
        break;
      default:
        break;
    }
  }

  updateInputSettings(enabled: boolean) {
    if (this.application) {
      this.application.stream.config.setFlagEnabled('MouseInput', enabled);
      this.application.stream.config.setFlagEnabled('TouchInput', enabled);
      this.application.stream.config.setFlagEnabled('KeyboardInput', enabled);
    }
  }

  sendTextareaToWs() {
    this.op.hide();
    this.application?.stream.emitUIInteraction({
        type: "application", payload: JSON.stringify({
            type: "paste",
            data: this.base64Service.stringToBase64(this.textAreaValue),
        }),
    });
    this.textAreaValue = '';
  }

}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MaintenanceService } from './service/maintenance.service';
import {catchError, map, of} from "rxjs";

export const maintenanceGuard: CanActivateFn = (route, state) => {
  const maintenanceService = inject(MaintenanceService);
  const router = inject(Router);

  return maintenanceService.checkMaintenance().pipe(
    map((response) => {
      if (response.in_maintenance) {
        // Reindirizza alla pagina di manutenzione
        return router.parseUrl('/maintenance');
      }
      return true; // Accesso consentito
    }),
    catchError((error) => {
      console.error('Failed to check maintenance state', error);
      // In caso di errore, consenti comunque l'accesso
      return of(true);
    })
  );
};

import {
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {LoginService} from "./service/login.service";
import {NotificationService} from "./service/notification.service";
import {Message, MessageService} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "./service/user.service";
import {TranslateService} from "@ngx-translate/core";
import * as Bowser from "bowser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'fe-museo';
  isAuthenticate = false
  browserBlacklist: any = [
    { browser: 'Safari', version: 15.3 },
    { browser: 'IE', version: 11 },
    { browser: 'Chrome', version: 98 },
    { browser: 'Edge', version: 98 },
    { browser: 'Firefox', version: 96 },
    { browser: 'Opera', version: 85 },
    { browser: 'Safari on IOS', version: 15.3 },
    { browser: 'Samsung Internet', version: 17.0 },
    { browser: 'Opera Mini', version: 36 },
    { browser: 'Opera Mobile', version: 12.1 },
    { browser: 'Android Browser', version: 4.4 },
    { browser: 'Firefox for Android', version: 0 }
  ];
  displayBrowserModal: boolean = false;
  browserName = "";
  browserVersion = 0;
  constructor(private notificationService: NotificationService,
              private changeDetectorRef: ChangeDetectorRef,
              private messageService: MessageService,
              private loginService: LoginService,
              private userService: UserService,
              private router: Router,
              private translate: TranslateService,
              private route: ActivatedRoute) {
    translate.addLangs(['en', 'it-IT', 'fr-FR', 'de-DE', 'es-ES']);
    const browserLanguageByAvailability = translate.getLangs().find(x => x === translate.getBrowserCultureLang()) ||
      translate.getLangs().find(x => x === translate.getBrowserLang());
    const localLanguage = localStorage.getItem('lang');
    // translate.use(this.normalizeLanguage(localLanguage || browserLanguageByAvailability || translate.getDefaultLang()));
    translate.use('it-IT');
  }
  normalizeLanguage(lang: string|undefined): string {
    switch (lang?.toLocaleLowerCase()) {
      case 'en' || 'us' || 'gb' || 'en-us' || 'en-gb':
        return 'en';
      case 'it' || 'it-it':
        return 'it-IT';
      case 'fr' || 'fr-fr':
        return 'fr-FR';
      case 'de' || 'de-de':
        return 'de-DE';
      case 'es' || 'es-es':
        return 'es-ES';
      default:
        return 'it-IT';
    }
  }
  ngOnInit() {
    if (this.isBrowserUnsupported()) {
      this.displayBrowserModal = true;
    }
    this.notificationService.message.subscribe(msg => {
      this.messageService.clear();
      this.messageService.add({severity: "error", summary:  msg || "There was an error."})
      this.changeDetectorRef.detectChanges()
    });
    this.loginService.isAuthenticatedUser().subscribe(res => {
      this.isAuthenticate = res;
      if (this.isAuthenticate) {
        this.userService.setCurrentUser();
      }
    });
  }

  showNavBar(): boolean {
    if (!this.route.snapshot.firstChild || this.route.snapshot.firstChild?.data['hideNavBar']) {
      return false;
    } else {
      return true;
    }
  }

  isBrowserUnsupported(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.browserName = browser.getBrowserName();
    this.browserVersion = parseFloat(browser.getBrowserVersion() || '0');

    return this.browserBlacklist.some(
      (item: { browser: string; version: number; }) => item.browser === this.browserName && this.browserVersion <= item.version
    );
  }

  hideWarning(): void {
    this.displayBrowserModal = false;
  }

}

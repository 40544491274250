import {HttpBackend, HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponse } from '../model/LoginResponse';
import { SignupRequest } from '../model/signupRequest';
import {LoginOtpResponse} from "../model/LoginOtpResponse";
import {LoginOtpRequest} from "../model/LoginOtpRequest";
import {BehaviorSubject, Observable} from "rxjs";
import {ValidateJwtResponse} from "../model/ValidateJwtResponse";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  apiUrl = "";
  isAuthenticated = new BehaviorSubject(false);
  private jwtToken: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  token$ = this.jwtToken.asObservable();
  private refreshToken: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  refreshToken$ = this.refreshToken.asObservable();
  private otpEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  otpEnabled$ = this.otpEnabled.asObservable();
  private requestId!: number;
  private otpToken!: string;

  // send http request without interceptor.
  private httpBe: HttpClient;
  constructor(private http: HttpClient, httpBackend: HttpBackend) {
    this.apiUrl = `${environment.domainSecure ? 'https://' : 'http://'}/${environment.domain}`
    this.httpBe = new HttpClient(httpBackend);
  }


  login(username: string, password: string) {
    this.logout();
    let body = {
      username: username,
      password: password,
      clientId: environment.client,
      grantType: 'password'
    }
    return this.http.post<LoginResponse | LoginOtpResponse>(this.apiUrl + '/api/v1/user/login', body);
  }

  loginAnonymous(): Observable<LoginResponse> {
    return this.login(environment.usernameAnonymous, environment.passwordAnonymous) as Observable<LoginResponse>;
  }

  sendOtp(otp: number) {
    let body: LoginOtpRequest = {
      requestId: this.requestId + "",
      token: this.otpToken,
      otp: otp + "",
      grantType: "authorization_code",
      clientId: environment.client
    }
    return this.http.post<LoginResponse>(this.apiUrl + '/api/v1/user/login', body);
  }
  validateJwt(jwt: string) {
    return this.httpBe.post<ValidateJwtResponse>(this.apiUrl + '/api/v1/user/verifyToken', {token: jwt}, {headers: {"client-id": environment.client}} );
  }

  refreshJwt(refreshToken: string) {
    let body = {
      refreshToken: refreshToken,
      grantType: "refresh_token",
      clientId: environment.client
    }
    return this.http.post<LoginResponse>(this.apiUrl + '/api/v1/user/login', body);
  }
  isAuthenticatedUser(): Observable<boolean> {
    return this.isAuthenticated;
  }

  setAuthenticatedUser(state: boolean) {
    this.isAuthenticated.next(state);
  }

  setRequestId(requestId: number) {
    this.requestId = requestId;
  }

  getRequestId(): number {
    return this.requestId;
  }

  setOtpToken(otpToken: string) {
    this.otpToken = otpToken;
  }

  getOtpToken(): string {
    return this.otpToken;
  }

  setJwt(token: string) {
    this.jwtToken.next(token);
    localStorage.setItem("token", token);
  }

  setOtpEnabled(otpEnabled: boolean) {
    this.otpEnabled.next(otpEnabled);
  }
  setRefreshToken(token: string) {
    this.refreshToken.next(token);
    localStorage.setItem("refreshToken", token);
  }
  async logout() {
    this.isAuthenticated.next(false);
    this.jwtToken.next(undefined);
    this.refreshToken.next(undefined);
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken');
  }

  async getTokenFromLocalStorage(): Promise<boolean> {
    console.log("called getTokenFromLocalStorage");
    if (localStorage.getItem('token') != null) {
      return new Promise<boolean>((resolve, reject) => {
        this.validateJwt(localStorage.getItem('token') as string).subscribe({
          next: response => {
            if (response.active) {
              this.setAuthenticatedUser(true);
              this.setJwt(localStorage.getItem('token') as string);
              this.setRefreshToken(localStorage.getItem('refreshToken') as string);
              this.isAuthenticated.next(true);
              console.log('Token is active');
              resolve(true);
            } else {
              this.refreshJwt(localStorage.getItem('refreshToken') as string).subscribe({
                next: response => {
                  if (response.access_token) {
                    this.setJwt(response.access_token);
                    this.setRefreshToken(response.refresh_token);
                    this.setAuthenticatedUser(true);
                    this.isAuthenticated.next(true);
                    console.log('Token is refreshed');
                    resolve(true);
                  }
                },
                error: err => {
                  console.error('Errore refresh token');
                  this.isAuthenticated.next(false);
                  resolve(false);
                }
              });
            }
          },
          error: err => {
            console.error('Errore validate token');
            this.isAuthenticated.next(false);
            resolve(false);
          }
        });
      });
    } else {
      console.log('LocalStorage empty!');
      return Promise.resolve(false);
    }
  }
}

<div id="login-container">
  <div id="login-header">
    <a href="/home"><img [src]="'/assets/image/logo-black.png'"></a>
    <div class="login-header-tools">
      <!--<app-language-selector></app-language-selector>-->
      <p-button class="login-btn" styleClass="login-btn-style" (onClick)="showLoginDialog()">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
          <g>
              <g id="signIn" transform="matrix(1,0,0,1,-26.1327,0.001)">
                  <path d="M40.964,21.659L36.138,26.485C35.881,26.735 35.535,26.876 35.176,26.876C34.42,26.876 33.797,26.253 33.797,25.497C33.797,25.138 33.937,24.793 34.187,24.536L36.659,22.064L27.577,22.064C26.821,22.064 26.198,21.441 26.198,20.685C26.198,19.929 26.821,19.306 27.577,19.306L36.659,19.306L34.187,16.834C33.929,16.576 33.783,16.225 33.783,15.86C33.783,15.103 34.405,14.481 35.162,14.481C35.527,14.481 35.878,14.627 36.136,14.885L40.962,19.711C41.496,20.246 41.496,21.125 40.962,21.66L40.964,21.659Z" style="fill:#171514;fill-rule:nonzero;"/>
              </g>
              <g id="signIn1" serif:id="signIn" transform="matrix(-1,0,0,1,41.2561,0.001)">
                  <path d="M33.094,11.72L33.094,5.515L22.063,5.515L22.063,35.851L33.094,35.851L33.094,29.646C33.094,28.89 33.717,28.267 34.473,28.267C35.229,28.267 35.852,28.89 35.852,29.646L35.852,37.23C35.852,37.986 35.229,38.609 34.473,38.609L22.063,38.609L22.063,39.988C22.062,40.744 21.44,41.366 20.684,41.367C20.619,41.367 20.554,41.362 20.489,41.353L1.184,38.6C0.506,38.503 -0.002,37.915 0,37.23L0,4.136C0,3.453 0.508,2.868 1.184,2.771L20.489,0.013C20.553,0.004 20.618,-0.001 20.683,-0.001C21.44,-0.001 22.062,0.621 22.063,1.378L22.063,2.757L34.473,2.757C35.229,2.757 35.852,3.38 35.852,4.136L35.852,11.72C35.852,12.476 35.229,13.099 34.473,13.099C33.717,13.099 33.094,12.476 33.094,11.72ZM19.3,37.23L19.3,2.967L2.758,5.332L2.758,36.032L19.3,38.4L19.3,37.228L19.3,37.23ZM15.858,19.3C15.102,19.3 14.479,19.923 14.479,20.679C14.479,21.435 15.102,22.058 15.858,22.058C16.614,22.058 17.237,21.435 17.237,20.679C17.237,19.923 16.614,19.3 15.858,19.3Z" style="fill:#171514;fill-rule:nonzero;"/>
              </g>
          </g>
        </svg>
      </p-button>
    </div>

  </div>
  <div class="container-lg">
    <div class="free-acess-container col-md-6 col-lg-5 mx-auto">

      <div class="menu-item d-flex align-items-center" (click)="anonymouseAccess()">
        <div class="menu-element d-flex align-items-center justify-content-center">
          <img [src]="'/assets/icons//shows.svg'">
        </div>
        <div>
          <dt>{{'home.exhibitionButton.label'|translate}}</dt>
          <dd>{{'home.exhibitionButton.description'|translate}}</dd>
        </div>
      </div>

    </div>
  </div>
</div>

<p-dialog header="Login" [(visible)]="isLoginDialogVisible" [modal]="true" [style]="{width: '578px'}" >
  <div class="form-container form-container-dark">
    <p-card>
      <div *ngIf="!showOtpInput" class="card-body">
        <div>
          <label for="email">{{'email'|translate}}</label>
          <input #email="ngModel" placeholder="" [(ngModel)]="username" type="email" id="email" pInputText required>
          <small *ngIf="!(email.valid || email.pristine)" id="email-help">{{'fieldRequired'|translate}}</small>
        </div>
        <div>
          <label for="password">{{'password'|translate}}</label>
          <input #pwd="ngModel" placeholder="" [(ngModel)]="password" type="password" id="password" pInputText required>
          <small *ngIf="!(pwd.valid || pwd.pristine)" id="pwd-help">{{'fieldRequired'|translate}}</small>
        </div>
        <div id="login-footer" class="d-flex flex-column">
          <p-button rel="nofollow" title="{{'login.forgottenPassword'|translate}}?" id="reset-password-btn" label="{{'login.forgottenPassword'|translate}}?" [link]="true" [routerLink]="'/reset-password'"></p-button>
          <p-messages key="loginError" [closable]="false" />
          <p-button rel="nofollow" id="signup" title="{{'login.login'|translate}}" (onClick)="login()">{{'login.login'|translate}}</p-button>
        </div>
      </div>

      <div *ngIf="showOtpInput" class="row">
        <div class="col-lg-8">
          <input [(ngModel)]="otp" placeholder="{{'login.insertOTPCode'|translate}}" type="number" id="otp" pInputText>
        </div>
        <div class="col-lg-4">
          <p-button rel="nofollow" title="{{'login.login'|translate}}" (click)="sendOtp()"><i class="pi pi-sign-in"></i></p-button>
        </div>
      </div>
    </p-card>
  </div>

</p-dialog>

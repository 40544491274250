import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {FormsModule} from '@angular/forms';
import {NavbarComponent} from './navbar/navbar.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './interceptor/TokenInterceptor';
import {PixelStreamingWrapperComponent} from './pixel-streaming-wrapper/pixel-streaming-wrapper.component';
import {SignupComponent} from './signup/signup.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MenubarModule} from "primeng/menubar";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {PasswordModule} from "primeng/password";
import {CardModule} from "primeng/card";
import {ErrorInterceptor} from './interceptor/ErrorInterceptor';
import {GlobalErrorHandler} from './handler/GlobalErrorHandler';
import {DialogModule} from "primeng/dialog";
import {ControlPanelComponent} from './control-panel/control-panel.component';
import {ToastModule} from "primeng/toast";
import { ConfirmationService, MessageService } from "primeng/api";
import {MfaComponent} from './mfa/mfa.component';
import {QRCodeModule} from 'angularx-qrcode';
import {PixelStreammingContainerComponent} from './pixel-streamming-container/pixel-streamming-container.component';
import {VerifyemailComponent} from './verifyemail/verifyemail.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {HomeComponent} from "./home/home.component";
import {CustomSharedModule} from "./custom-shared/custom-shared.module";
import {LoginService} from "./service/login.service";
import { AssetUploadComponent } from './asset-upload/asset-upload.component';
import {ProgressBarModule} from "primeng/progressbar";
import { NavbarUserspaceComponent } from './navbar/navbar-userspace/navbar-userspace.component';
import { MetricsComponent } from './metrics/metrics.component';
import { MainmenuComponent } from './mainmenu/mainmenu.component';
import {TableModule} from "primeng/table";
import {FileUploadModule} from "primeng/fileupload";
import { TableMetricsComponent } from './table-metrics/table-metrics.component';
import { AdminControlPanelComponent } from './admin-control-panel/admin-control-panel.component';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslatePipe} from "@ngx-translate/core";
import {DropdownModule} from "primeng/dropdown";
import {ContextMenuModule} from "primeng/contextmenu";
import {SpeedDialModule} from "primeng/speeddial";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditUserComponent } from './edit-user/edit-user.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticsAssetsComponent } from './statistics/statistics-assets/statistics-assets.component';
import { StatisticsRoomsComponent } from './statistics/statistics-rooms/statistics-rooms.component';
import { StatisticsExhibitionsComponent } from './statistics/statistics-exhibitions/statistics-exhibitions.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChartModule } from 'primeng/chart';
import { MetricTimePipe } from './statistics/timePipe.pipe';
import { PaginatorModule } from 'primeng/paginator';
import { DeleteAccountSuccessLandingPageComponent } from './delete-account-success-landing-page/delete-account-success-landing-page.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import {KnobModule} from "primeng/knob";
import { GalleriaModule } from 'primeng/galleria';
import { CalendarModule } from 'primeng/calendar';
import { TimeFilterComponent } from './table-metrics/time-filter/time-filter.component';
import {TagModule} from "primeng/tag";
import {AccordionModule} from "primeng/accordion";
import { StatisticsExportComponent } from './statistics-export/statistics-export.component';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { StreamingDebugOverlayComponent } from './streaming-debug-overlay/streaming-debug-overlay.component';
import { FpsIndicatorComponent } from './streaming-debug-overlay/fps-indicator/fps-indicator.component';
import { AssetManagerComponent } from './asset-manager/asset-manager.component';
import { DebugLogManagerComponent } from './debug-log-manager/debug-log-manager.component';
import {CheckboxModule} from "primeng/checkbox";
import {MaintenanceService} from "./service/maintenance.service";
import {Router} from "@angular/router";
import { MaintenanceComponent } from './maintenance/maintenance.component';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const I18N_CONFIG = {
  defaultLanguage: 'it-IT', // this name need to be the same as the JSON file
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}

export function checkMaintenanceFactory(
  maintenanceService: MaintenanceService,
  router: Router
): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise((resolve, reject) => {
      maintenanceService.checkMaintenance().subscribe({
        next: (response) => {
          if (response.in_maintenance) {
            // Redirect alla pagina di manutenzione
            router.navigate(['/maintenance']);
          }
          resolve(); // Completa l'inizializzazione
        },
        error: (err) => {
          console.error('Failed to check maintenance state:', err);
          resolve(); // Completa l'inizializzazione
        },
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    PixelStreamingWrapperComponent,
    SignupComponent,
    ControlPanelComponent,
    MfaComponent,
    PixelStreammingContainerComponent,
    VerifyemailComponent,
    PasswordResetComponent,
    UpdatePasswordComponent,
    HomeComponent,
    AssetUploadComponent,
    NavbarUserspaceComponent,
    MetricsComponent,
    MainmenuComponent,
    TableMetricsComponent,
    AdminControlPanelComponent,
    FileUploadModalComponent,
    EditUserComponent,
    StatisticsComponent,
    StatisticsAssetsComponent,
    StatisticsRoomsComponent,
    StatisticsExhibitionsComponent,
    MetricTimePipe,
    DeleteAccountSuccessLandingPageComponent,
    SystemStatusComponent,
    TimeFilterComponent,
    StatisticsExportComponent,
    StreamingDebugOverlayComponent,
    FpsIndicatorComponent,
    AssetManagerComponent,
    DebugLogManagerComponent,
    MaintenanceComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    PasswordModule,
    CardModule,
    DialogModule,
    ToastModule,
    QRCodeModule,
    CustomSharedModule,
    ProgressBarModule,
    TableModule,
    FileUploadModule,
    NgbModule,
    TranslateModule.forRoot(I18N_CONFIG),
    DropdownModule,
    ContextMenuModule,
    SpeedDialModule,
    ConfirmDialogModule,
    InputSwitchModule,
    OverlayPanelModule,
    ChartModule,
    PaginatorModule,
    KnobModule,
    GalleriaModule,
    CalendarModule,
    TagModule,
    AccordionModule,
    ProgressSpinnerModule,
    CheckboxModule,
  ],
  providers: [
    MaintenanceService,
    {provide: APP_INITIALIZER, useFactory: checkMaintenanceFactory, deps: [MaintenanceService, Router], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: APP_INITIALIZER, useFactory: (loginService: LoginService) => {
      console.log('INIT APP!');
      return async () => {
        return await loginService.getTokenFromLocalStorage()
      };
    }, deps: [LoginService, HttpClient, MaintenanceService, Router], multi: true},
    MessageService,
    MetricTimePipe,
    TranslatePipe,
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

export const environment = {
  domainSecure: true,
  domain: 'api-observa-test.acsoftware.it',
  client: 'client-observa',
  matchmakingSecure: false,
  matchmakingDomain: 'https://api-observa-test.acsoftware.it/matchmaker',
  matchmakingTestDomain: 'observa.acsoftware.it/signaling',
  matchmakingEnable: true,
  matchmakingTimeout: 5,
  usernameAnonymous: 'guest-observa@yopmail.com',
  passwordAnonymous: 'prova',
  DisableFreeAccess: false,
  exportProjectId: 6399,
  exportTypeIds: {
    exhibition: 6403,
    asset: 6438,
    room: 6622,
    attachment: 6748,
    link: 6761,
  },
  streamingDebug: true,
  streamingDebugLevel: 1,
};
